import React, {useState} from "react";
import {useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";
import "./Footer.css";
import Image from "../../components/Image";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import LogoAppleAppstore from 'react-ionicons/lib/LogoAppleAppstore';
import LogoGooglePlaystore from 'react-ionicons/lib/LogoGooglePlaystore';
import parse from 'html-react-parser';
import clevertap from "clevertap-web-sdk";
import LazyLoad from "react-lazyload";


const socials = [
  {
    title: "telegram",
    size: "16",
    url: "https://t.me/exziofficial",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/EXZIOfficial",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/exziofficial",
  }
];

const page = "footer";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
  /**
   * Check device type
   *
   * @type {boolean}
   */
  const isTablet = useMediaQuery({query: "(max-width: 1023px)"});

  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  const accountInfo = useSelector(state => state.user.account_info);

  const {t} = useTranslation();

  const services = [
    {
      title: t(`${page}.services.0`),
      url: "/help/exzi-beginners-guide",
    },
    {
      title: t(`${page}.services.1`),
      url: "/help-center",
    },
    {
      title: t(`${page}.services.2`),
      url: "/legal",
    },
    {
      title: t(`${page}.services.3`),
      url: "/faq",
    },
    {
      title: t(`${page}.services.4`),
      url: "https://ua5p6qeixpo.typeform.com/to/VoIicwB8",
      onClick: () => {
        // Support chat clicked  properties:[{"userId":"integer"}]
        clevertap.event.push("support_chat_clicked", {
          userId: accountInfo?.profile?.id
        })
      }
    },

    // {
    //   title: t(`${page}.services.2`),
    //   url: "/api-docs",
    // }
  ];

  const company = [
    {
      title: t(`${page}.menu.0`),
      url: "/trading",
    },
    {
      title: t(`${page}.menu.1`),
      url: "/markets",
    },
    {
      title: t(`${page}.menu.2`),
      url: "/career",
    },
    {
      title: t(`${page}.menu.3`),
      url: "/proof-of-reserves",
    },
    {
      title: t(`${page}.menu.4`),
      url: "/fees",
    }
  ];

  const terms = [
    {
      title: t(`${page}.terms.0`),
      url: "/legal/terms-and-conditions",
    },
    {
      title: t(`${page}.terms.1`),
      url: "/legal/privacy-policy",
    },
    {
      title: t(`${page}.terms.2`),
      url: "/legal/complaints-policy",
    },
  ];

  const mobileMenu = [
    {
      title: t(`${page}.mobileMenu.0`),
      icon: "Home",
      url: "/",
    },
    {
      title: t(`${page}.mobileMenu.1`),
      icon: "Markets",
      url: "/markets",
    },
    {
      title: t(`${page}.mobileMenu.2`),
      icon: "Trading",
      url: "/trading",
    },
    {
      title: t(`${page}.mobileMenu.3`),
      icon: "Wallet",
      url: "/wallet-overview",
    }
  ];

  const {search, pathname} = useLocation();
  const token = new URLSearchParams(search).get("token");
  const sign = new URLSearchParams(search).get("sign");
  const webview = pathname.includes("webview");
  const isHomePage = pathname === '/';

  const isExternal = (url) => {
    return /^https?:\/\//.test(url);
  };

  /**
   * Render HTML
   */
  if(!webview){
  return (
    <footer>
      <div className={`footer  ${isHomePage ? "bg-black" : "main"}`}>
        <div className="footercontainer w-container">
          <div className="w-layout-grid footer-links-grid">
            <div className="footer-link-column">
              <div className="footer-links-title">{t(`${page}.servicesInfo`)}</div>
              {services.map((x, index) => {
                if (isExternal(x.url)) {
                  return (
                      <a href={x.url} key={index} onClick={x?.onClick} className="footer-link-2" target="_blank" rel="noopener noreferrer">
                        {x.title}
                      </a>
                  );
                } else {
                  return (
                      <NavLink to={x.url} key={index} onClick={x?.onClick} className="footer-link-2">
                        {x.title}
                      </NavLink>
                  );
                }
              })}
            </div>
            <div className="footer-link-column">
              <div className="footer-links-title">{t(`${page}.companyInfo`)}</div>
              {company.map((x, index) => (
                <NavLink to={x.url} key={index} className="footer-link-2">
                  {x.title}
                </NavLink>
              ))}
            </div>
            <div className="footer-link-column">
              <div className="footer-links-title">{t(`${page}.communityInfo`)}</div>
              <NavLink to="/referral" className="footer-link-2" target="_blank">{t(`${page}.companyMenu.0`)}</NavLink>
              <NavLink to="/campaign/beta-testing-campaign" className="footer-link-2" target="_blank">{t(`${page}.companyMenu.1`)}</NavLink>
              <a href="https://hackenproof.com/exzi/exzi-web-and-mobile" target="_blank" rel="noopener noreferrer" className="footer-link-2">
                {t(`${page}.companyMenu.2`)}
              </a>
            </div>
            
            <div className="footer-link-column">
              <div className="footer-links-title">{t(`${page}.tradeNow`)}</div>
              <NavLink to="/" target="_blank" className="footer-link-2">{t(`${page}.scanStart`)}</NavLink>
              <Image
                // style={{ marginLeft: "auto" }}
                src={isHomePage ? "/images/home/qr.svg" : "/images/home/qr-dark.svg"}
                srcDark="/images/home/qr.svg"
                alt=""
                className="pt-2 isDesktop w-4/5"
              />

              <div className="flex gap-3  pt-2 isMobile">
                <a href="https://play.google.com/store/apps/details?id=com.exzi.app" target="_blank">
                  <LogoGooglePlaystore
                    color={'rgb(147, 150, 185)'}
                    height="25px"
                    width="25px"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/exzi-buy-sell-btc-eth-crypto/id6451389176" target="_blank">
                  <LogoAppleAppstore
                    color={'rgb(147, 150, 185)'}
                    height="25px"
                    width="25px"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* <br /> */}
          <br/>
          <div className="footerlegal">
            <LazyLoad offset={200}>
              <img
                src="/images/home/exzi.svg"
                loading="lazy"
                alt=""
                className="image-38"
              />
            </LazyLoad>
            
            <div className="socialicons">
              <a
                href="https://twitter.com/EXZIOfficial"
                target="_blank"
                className="socialicon w-inline-block"
              >
                <LazyLoad offset={200}>
                  <img src="/images/home/x.svg" loading="lazy" alt="" />
                </LazyLoad>
                
              </a>
              <a
                href="https://t.me/ExziGlobal"
                target="_blank"
                className="socialicon w-inline-block"
              >
                <LazyLoad offset={200}>
                  <img src="/images/home/tg.svg" loading="lazy" alt="" />
                </LazyLoad>
                
              </a>
              <a
                href="https://www.instagram.com/exziofficial"
                target="_blank"
                className="socialicon w-inline-block"
              >
                <LazyLoad offset={200}>
                  <img src="/images/home/instagram.svg" loading="lazy" alt="" />
                </LazyLoad>
                
              </a>
              <a
                href="https://www.linkedin.com/company/exziofficial"
                target="_blank"
                className="socialicon w-inline-block"
              >
                <LazyLoad offset={200}>
                  <img src="/images/home/linkedin-icon.svg" loading="lazy" alt="" />
                </LazyLoad>
                
              </a>
            </div>
          </div>
          <div className="dividerline" />
          <div className="footertext flex gap-5">
            <div className="flex flex-col md:flex-row gap-3" style={{minWidth: 50}}>
              <LazyLoad offset={200}>
                <img src="/images/home/fcis.png" width={50} alt=""/>
              </LazyLoad>
              <span className="hidden md:block footer-spl-lin"></span>
              <img src="/images/home/hacken.png" className="isMobile" width={50} alt=""/>
              <div className="isDesktop">
                <LazyLoad offset={200}>
                  <Image
                    style={{ minWidth: '100px', maxWidth: '100px' }}
                    src={isHomePage ? "/images/home/hacken-light.png" : "/images/home/hacken-dark.svg"}
                    srcDark="/images/home/hacken-light.png"
                    alt="hacken"
                  />
                </LazyLoad>
              </div>
              {/* Copyright © 2023 EXZi LLC. All rights reserved */}
              {/* <br/>  <br/> */}
              {!!+(process.env.REACT_APP_SHOW_FOOTER_TEXT ?? 1) && parse(t(`${page}.text1`))}
            </div>
          </div>
          {/*<div className="footertext">*/}
          {/*  <br/>*/}
          {/*  {parse(t(`${page}.text2`))}*/}
          {/*</div>*/}
          {/* <div className="legallinks">
                {terms.map((x, index) => (
                  <NavLink to={x.url} key={index} className="legallink">
                    {x.title}
                  </NavLink>
                ))}
              </div> */}
        </div>
      </div>
      <script type="application/ld+json">
        {`{"@context":"https://schema.org","@type":"Organization","name":"EXZi","url":"https://exzi.com/","logo":"https://exzi.com/images/logo-light.svg","foundingDate":"2023","founder":{"@type":"Person","name":"Kurucu Ismi"},"sameAs":["https://twitter.com/EXZIOfficial","https://www.instagram.com/exziofficial","https://t.me/ExziGlobal","https://www.linkedin.com/company/exziofficial"],"location":[{"@type":"Country","name":"Lithuania"},{"@type":"Country","name":"United Kingdom"}],"memberOf":[{"@type":"Organization","name":"Fireblocks","url":"https://www.fireblocks.com/"},{"@type":"Organization","name":"Aquanow","url":"https://www.aquanow.io/"},{"@type":"Organization","name":"Ondato","url":"https://ondato.com/"},{"@type":"Organization","name":"TradingView","url":"https://www.tradingview.com/"},{"@type":"Organization","name":"ScoreChain","url":"https://www.scorechain.com/"},{"@type":"Organization","name":"Hawk:AI","url":"https://hawk.ai/"},{"@type":"Organization","name":"Twilio","url":"https://www.twilio.com/en-us"}]}`}
      </script>
    </footer>
  );
  }
};

export default Footer;
