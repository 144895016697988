import {lazy} from "react";

// ## Pages
/**
 *
 * @type {[]}
 */
export const PublicRoutesList = [
    // {
    //     path: "/page-list",
    //     component: lazy(() => import("../screens/PageList")),
    // },
    {
        path: "/refcode/:refCode?",
        component: lazy(() => import("../utils/GetRefCode")),
    },
    {
        path: "/trading",
        component: lazy(() => import("../screens/Exchange")),
        sitemap: {
            priority: 1,
        }
    },
    {
        path: "/trading/:pairName",
        component: lazy(() => import("../screens/Exchange")),
    },
    {
        path: "/markets",
        component: lazy(() => import("../screens/Markets")),
        sitemap: {
            priority: 1,
        }
    },
    {
        path: "/career",
        component: lazy(() => import("../screens/Career")),
    },
    {
        path: "/faq",
        component: lazy(() => import("../screens/Faq")),
    },
    {
        path: "/blog",
        component: lazy(() => import("../screens/LearnCrypto")),
    },
    {
        path: "/blog/:category/:slug",
        component: lazy(() => import("../screens/LearnCryptoDetails")),
    },
    {
        path: "/legal/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/privacy-policy",
        component: lazy(() => import("../screens/HelpDetails/Redirects/PrivacyPolicyRedirect")),
    },
    {
        path: "/campaign/:slug",
        component: lazy(() => import("../screens/LearnCryptoDetails")),
    },
    {
        path: "/webview/campaign/:slug",
        component: lazy(() => import("../screens/LearnCryptoDetails")),
    },
    {
        path: "/getting-started/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/top-questions/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/help/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/account-kyc/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/contact",
        component: lazy(() => import("../screens/Contact")),
    },
    // {
    //     path: "/notifications",
    //     component: lazy(() => import("../screens/Notifications")),
    // },
    // {
    //     path: "/buy-crypto",
    //     component: lazy(() => import("../screens/BuyCrypto")),
    // },
    // {
    //     path: "/sell-crypto",
    //     component: lazy(() => import("../screens/SellCrypto")),
    // },
    {
        path: "/download",
        component: lazy(() => import("../screens/Download")),
    },
    {
        path: "/legal",
        component: lazy(() => import("../screens/LegalDocuments")),
    },
    {
        path: "/referrals",
        component: lazy(() => import("../screens/Referrals")),
    },
    {
        path: "/vip-info",
        component: lazy(() => import("../screens/VipInfo")),
    },
    {
        path: "/webview/legal",
        component: lazy(() => import("../screens/LegalDocuments")),
    },
    
    // --- Launchpad
    {
        path: "/launchpad",
        component: lazy(() => import("../screens/Launchpad")),
    },
    {
        path: "/launchpad/:link",
        component: lazy(() => import("../screens/LaunchpadProject")),
    },
    {
        path: "/webview/launchpad",
        component: lazy(() => import("../screens/Launchpad")),
    },
    {
        path: "/webview/launchpad/:link",
        component: lazy(() => import("../screens/LaunchpadProject")),
    },
    {
        path: "/webview/subscription-history",
        component: lazy(() => import("../screens/LaunchpadSubscriptionHistory")),
    },
    {
        path: "/",
        component: lazy(() => import("../screens/Home")),
        sitemap: {
            priority: 1,
        }
    },
    {
        path: "/leaderboard",
        component: lazy(() => import("../screens/Leaderboard")),
        sitemap: {
            priority: 1,
        }
    },
    {
        path: "/referral",
        component: lazy(() => import("../screens/Referral")),
        sitemap: {
            priority: 1,
        }
    },
    {
        path: "/proof-of-reserves",
        component: lazy(() => import("../screens/Safu")),
    },
    // --- Voting
    // {
    //     path: "/voting",
    //     component: lazy(() => import("../screens/Voting")),
    // },
    // {
    //     path: "/webview/voting",
    //     component: lazy(() => import("../screens/Voting")),
    // },
    // {
    //     path: "/webview/voting/user-center/:id",
    //     component: lazy(() => import("../screens/Voting/UserCenter")),
    // },
    {
        path: "/fees",
        component: lazy(() => import("../screens/Fees")),
    },
    {
        path: "/price-alerts",
        component: lazy(() => import("../screens/Exchange/PriceAlerts")),
    },
    {
        path: "/webview/verification", // "/webview/verification?token={token}"
        component: lazy(() => import("../screens/KYC/WebView")),
    },
    {
        path: "/webview/verification/:step", // "/webview/verification?token={token}"
        component: lazy(() => import("../screens/KYC/WebView")),
    },
    {
        path: "/verification/action-required/:code", // "/verification/action-required/{code}?key={token}"
        component: lazy(() => import("../screens/KYC/ActionRequired")),
        // component: lazy(() => import("../screens/KYC"))
    },
    {
        path: "/unsubscribe-success",
        component: lazy(() => import("../screens/UnsubscribeSuccess")),
    },
    {
        path: "/help-center",
        component: lazy(() => import("../screens/HelpCenter")),
    },
    {
        path: "/help-center/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/help-center/:category/:slug",
        component: lazy(() => import("../screens/HelpDetails")),
    },
    {
        path: "/:category/:slug",
        component: lazy(() => import("../screens/LearnCryptoDetails")),
    },
];
